<template>
  <b-card-code
    no-body
    title="All Users"
  >
    <button v-if="this.$store.state.app.user.permissions.includes('user.add_user')" @click="addUser()" type="button" class="btn ml-auto btn-primary m-1">
      Add New
    </button>

    <b-table
      responsive="true" id="orgTable" :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
    >
    <template #cell(roles)="data">   
        <b-badge variant="primary" v-for="item in data.item.groups" :key="item.id">
            {{ item.name }}
        </b-badge>      
    </template>

    <template #cell(status)="data">   
        <b-badge variant="success" v-if="data.item.is_active">Yes</b-badge>   
        <b-badge variant="danger" v-else>No</b-badge>    
    </template>   

    <template #cell(staff)="data" >   
        <b-badge variant="success" v-if="data.item.is_staff">Yes</b-badge>   
        <b-badge variant="danger" v-else>No</b-badge>    
    </template> 

    <template #cell(actions)="data" >
        <b-dropdown
            size="sm"
            class="ml-1"
            variant="outline-primary"
            text="Actions"
        >
            <b-dropdown-item @click="editUser(data.item.id)">Edit</b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="deleteUser(data.item.id)">Delete</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>


   <b-pagination
    v-model="currentPage"
    :total-rows="rows"
    :per-page="perPage"
    :align="pagination_pos"
    aria-controls="orgTable"
  />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable } from 'bootstrap-vue'
import { BPagination,BPaginationNav, BButton, BBadge,BDropdown, BDropdownItem, BDropdownDivider,  } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BDropdown, BDropdownItem, BDropdownDivider, 
  },
  data() {   
    return {
      pagination_pos: 'center',
    // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows:0,
      fields: [
        // { key: 'id', label: '#' },
        { key: 'email', label: 'Email(Username)' },
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        // { key: 'profile.organization', label: 'Organization' },
        { key: 'roles', label: 'Roles' },
        { key: 'status', label: 'Status' },
        { key: 'staff', label: 'Staff' },
        { key: 'actions', label: 'Actions' }
      ],
    }
  },
  created: function () {
                this.load();
            },
  methods: {
    load: function () {
        const options = {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
            url: process.env.VUE_APP_BASEURL+'user/users',
          }
          this.$http(options).then( ( res ) => {
              console.log(res.data)
              // res.data.map(function(value, key) {
              //   // console.log(res.data[key].profile.organization)
              //    if(res.data[key].profile.organization != null){
              //       res.data[key].profile.organization = res.data[key].profile.o_profile.org_name
              //    }
              // });
              this.items = res.data;
              console.log(this.items)
              this.rows = res.data.length;
            } )
    },
    addUser(){
      this.$router.push({name: 'Add User'});
    },
    editUser(id){
      this.$router.push({name: 'Edit User', params: { id: id}});
    },
    deleteUser(id){
        if(confirm("Do you really want to delete?")){
            const options = {
              method: 'DELETE',
              headers: { 'content-type': 'application/json' },
              url: process.env.VUE_APP_BASEURL+'user/users/'+id+"/",
          }
          this.$http(options).then( ( res ) => {
            console.log(res)
            this.load();
            //if(res.data.org_id){
            // this.$router.push({name: 'Organizations'});
           // }
          })          
        }
    }
  }
}
</script>